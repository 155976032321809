
/* This fix the logout button and the header margin, which is not present when the app width is larger than 992px */
@media (min-width: 992px) {
    .header-fixed .app-body {
        margin-top: 0px!important;
    }
    .logout {
        top: .5rem!important;
    }
}

/* This set the CC icon */
.icon-cc {
    background-image: url('./assets/img/brand/icon.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.25rem;
    margin-bottom: -5px!important;
}


/* This is for the badge that is on the sidebar that links to CC landing page */
.sidebar .nav-link.nav-link-custom.active {
    background-color: #73ba65;
    transition: background-color ease .5s;
}

.sidebar .nav-link.nav-link-custom.active:hover {
    background-color: #549248;
    transition: background-color ease .5s;
}

/* CMS text on Login view*/

.cms {
    font-family: 'Times New Roman';
    font-weight: 600;
    color: #534d4d;
}

/* STYLE FOR REUSABLE CARD */

.card.reusableCard .card-body {
	height: 200px;
	color: #343a40;
}

.card.reusableCard {
    transition: all .3s ease;
    cursor: pointer;
}

.card.reusableCard:hover {
    background-color: #f5f5f5;
    transition: all .3s ease;
}

.card.reusableCard .card-header {
    transition: all .5s ease;
}

.card.reusableCard:hover .card-header {
    background-color: #363636;
    color: #f5f5f5;
    transition: all .5s ease;
}

.icon-wrapper {
	transform: translateY(30px);
	transition: transform ease .3s;
}
.card.reusableCard:hover .icon-wrapper {
	transform: translateY(0px);
	transition: transform ease .3s;
}

.card.reusableCard .description {
	opacity: 0;
	transition: opacity ease .2s;
}

.card.reusableCard:hover .description {
	opacity: 1;
	transition: opacity ease .9s;
}

/* Styles for user email desplayed on breadcrumbs */


@media(min-width: 992px) {
    .user-email {
        position: 'absolute';
        top: '.6rem';
        right: '4rem';
        padding: '0'
    }
}

.user-email {
    margin-right: 1rem;
    padding: 0;
    transition: all .3s ease;
}

.user-email:hover {
    color: #73ba65!important;
    transition: all .3s ease;
}

/* Styles for titles in cards */

.card-body h4 {
	color: #343a40;
}

.buttonForOptions {
    position: absolute!important;
    right: -35px;
    top: top;
    margin-top: -35px;
}

.btn-group.buttonForOptions {
    right: -75px!important;
}

.buttonForOptions.delete, .buttonForOptions .add, .buttonForOptions .delete, .buttonForOptions.delete:hover, .buttonForOptions .add:hover, .buttonForOptions .delete:hover {
    background-color: transparent;
    border-color: transparent;
    text-decoration: none;
}

.buttonForOptions.delete, .buttonForOptions .delete {
    color: #dc3545;
    text-decoration: none;
}

.buttonForOptions .add {
    color: #73ba65;
    text-decoration: none;
}

.buttonForOptions.delete:hover, .buttonForOptions .delete:hover {
    color: #c51729;
    text-decoration: none;
}

.buttonForOptions .add {
    color: #348123;
    text-decoration: none;
}

/* DRAGGABLE  */

.cursor-draggable {
    cursor: move;
  }
  .main {
    overflow: hidden;
  }
  .react-grid-item.react-grid-placeholder {
    background: #73ba65!important;
    opacity: 0.4!important;
    border: #549248 dashed!important;
  }

.icon-draggable {
    font-size: 1.5rem;
    opacity: 0;
    position: absolute;
    left: -25px;
    top: 5px;
    transition: all .3s ease;
}

.react-draggable:hover .icon-draggable {
    opacity: .6;
    transition: all .3s ease;
}

.error-tooltip .tooltip-inner {
    /* max-width: 200px;
    padding: .25rem .5rem; */
    color: #fff;
    /* text-align: center; */
    background-color: #dc3545!important;
    /* border-radius: .25rem; */
}

.error-tooltip .bs-tooltip-auto .arrow::before {
    border-right-color: #dc3545!important;
}

/* ANIMATION FOR LOADING SCREEN */

.animated.infinite {
    animation-iteration-count: infinite;
}

@keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  
  .pulse {
    animation-name: pulse;
    animation-timing-function: ease-in-out;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  .fadeOut {
    animation-name: fadeOut;
    animation-timing-function: ease-in-out;
  }

  /* Styles for tabset that is displayed on Prospects Management  */

.card .card-body .rwt__tablist .rwt__tab[aria-selected="true"]:after {
      border-bottom: 3px solid #73ba65!important;
  }

  .card .card-body .rwt__tab {
      text-align: left;
  }

  /* Styles for discard button in Prospects Management */

  .card .card-body .discard-button {
    color: grey;
  }

  .card .card-body .discard-button:hover, .card .card-body .discard-button:active, .card .card-body .discard-button:focus {
    text-decoration: none;
    background-color: rgba(128, 128, 128, 0.1);
  }
  
  /* Styles for filter search input in Prospects management */

  .card .card-body .input-group .real-time-search.form-control {
    border: none!important;
    border-bottom: 1px solid #e4e7ea!important;
    border-bottom-left-radius: 0!important;
  }

  .card .card-body .input-group .real-time-search.form-control:focus {
    box-shadow: none;
  }

  .card .card-body .d-flex .input-group .input-group-text {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #e4e7ea!important;
      /* transform: translateX(-30px); */
      z-index: 11;
  }

  .real-time-search ~ .focus-border{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #73ba65;
    transition: 0.4s;
    z-index: 12;
}
.real-time-search:focus ~ .focus-border{
    width: 100%;
    transition: 0.4s;
}

/*  */

input[type="checkbox"] {
    margin-left: 0;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    color: #363839;
    border: 1px solid #bdc1c6;
    border-radius: 4px;
    margin-top: 0;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
input[type="checkbox"]:checked:disabled {
    color:#fff;
    border-color: #0daf3e57;
    background: #73ba6583;
}
input[type="checkbox"]::before {
    position: absolute;
    content: '';
    display: block;
    top: 2px;
    left: 7px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}
input[type="checkbox"]:checked {
    color:#fff;
    border-color: #0daf3e;
    background: #73ba65;
}
input[type="checkbox"]:checked::before {
    opacity: 1;
}
input[type="checkbox"]:checked::before ~ label::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* TABLE STYLES */
.card .card-body .table {
    min-height: 100px;
}

.card .card-body .table th {
    border-top: none;
}


.card .card-body .table  thead th {
    background-color: #2f353a;
    color: white;
    text-align: center;
}

.card .card-body .table-bordered th, .card .card-body .table-bordered td {
    border: none;
    border-top: 1px solid #c8ced3;
}

.table h4.empty-table-message {
    color: #73ba65;
    text-align: center
}

.dropdown .dropdown-toggle.btn.btn-secondary, .dropdown .dropdown-toggle.btn.btn-secondary:active, .dropdown .dropdown-toggle.btn.btn-secondary:focus {
    color: #fff;
    background-color: #73ba65;
    border-color: #73ba65;
}

/* Access Table */

.table .access-logs .row-expansion-style {
    padding: 0;
}

.access-logs .react-bootstrap-table {
    max-height: 220px;
    overflow-y: scroll;
}

.row-expansion-style .table {
    max-height: 300px;
    margin-bottom: 0;
}

.card .card-body .table th {
    border-top: none;
}


.card .card-body .table .row-expansion-style .table  thead th {
    background-color: white;
    color: #2f353a;
    text-align: center;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.07);
}

.card .card-body .row-expansion-style .table-bordered  th, .card .card-body .row-expansion-style .table-bordered td {
    border: 1px solid #c8ced3;
}

.card .card-body .row-expansion-style .table-bordered  tfoot th {
    border: none;
}


#prospects-management  .access-logs .table thead th[aria-label="Date sortable"], #prospects-management  .access-logs .table thead th[aria-label="Date sort asc"], #prospects-management  .access-logs .table thead th[aria-label="Date sort desc"], #prospects-management  .access-logs .table thead th:last-child {
    width: auto;
}

/* BOOTSTRAP TABLE STYLES */
.react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
}

.position-sticky {
    position: sticky;
    top: 0;
}

.react-bootstrap-table th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    /* opacity: 0.4; */
}
.react-bootstrap-table th .order-4:after {
    content: "\2193";
}

.react-bootstrap-table th .caret-4-desc:after {
    content: "\2193";
}

.react-bootstrap-table th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

#prospects-management .table thead th[aria-label="First Name sortable"], #prospects-management .table thead th[aria-label="First Name sort asc"], #prospects-management .table thead th[aria-label="First Name sort desc"], #prospects-management .table thead th[aria-label="Last Name sortable"], #prospects-management .table thead th[aria-label="Last Name sort asc"], #prospects-management .table thead th[aria-label="Last Name sort desc"] {
    width: 9%;
}

#prospects-management .table thead th[aria-label="Date sortable"], #prospects-management .table thead th[aria-label="Date sort asc"], #prospects-management .table thead th[aria-label="Date sort desc"], #prospects-management .table thead th:last-child {
    width: 10%;
}

@media(max-width: 1200px) {
    #prospects-management .table thead th[aria-label="Date sortable"], #prospects-management .table thead th[aria-label="Date sort asc"], #prospects-management .table thead th[aria-label="Date sort desc"], #prospects-management .table thead th:last-child {
        width: 14%;
    }
}

#prospects-management #prospects-management .table thead th[aria-label="Location sortable"], #prospects-management .table thead th[aria-label="Location sort asc"], #prospects-management .table thead th[aria-label="Location sort desc"] {
    width: 12%;
}

#prospects-management .table thead th:nth-child(6) {
    width: 13%;
}

/* PDF ICON STYLES */

.pdf-image {
    z-index: 1;
    transform: translateY(0px);
    transition: all ease .3s;
}

.down-arrow {
    transform: translateY(-5px);
    opacity: 0;
    bottom: 0;
    left: 50%;
    position: absolute;
    transition: all ease .3s;
}

.downlad-pdf:hover .pdf-image {
    transform: translateY(-10px);
    transition: all ease .3s;
}

.downlad-pdf:hover .down-arrow {
    transform: translateY(0px);
    opacity: 1;
    transition: all ease .3s;
}

/* DATE PICKER STYLES */

.DayPickerInput input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DayPickerInput-OverlayWrapper {
    z-index: 1000;
}

 .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #73ba65!important;
    color: #ffffff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    position: relative;
    background-color: #5e9752!important;
    color: #ffffff;
}

.date-pickers .DayPickerInput input { /* On Dashboard */
    border-radius: 0rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

#visitors-type .text-muted {
    color:white!important;
}

/* Logout button */

.btn.logout.slide-left {
    transform: translateX(0px);
    transition: transform ease .3s;
}

.btn.logout.slide-left:hover {
    transform: translateX(-5px);
    transition: transform ease .3s;
}